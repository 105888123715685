<template>
  <mymap
    ref="myMap"
    :locations="locations"
    :hashURL="$route.params.hash"
    :hideMarker="true"
    height="100vh"
  ></mymap>
</template>

<script>
import Map from '../components/Map.vue';
import axios from '@/libs/axios';
import store from '@/store';
import { useRoute } from 'vue-router';
import useJwt from '@/auth/iframejwt/useJwt';

import { ref, onMounted } from '@vue/composition-api';
export default {
  components: {
    mymap: Map,
  },
  setup(props, context) {
    const locations = ref([]);

    onMounted(async () => {
      const authenticated = await authenticate();
      if (authenticated) {
        fetchData();
        if (!store.state.auth.userData)
          store.dispatch('devices/UPDATE_ONLINE_DEVICES');
        setInterval(() => {
          if (!store.state.auth.userData)
            store.dispatch('devices/UPDATE_ONLINE_DEVICES');
        }, 10000);
      }
    });

    const authenticate = async () => {
      const response = await useJwt
        .login({
          email: 'iframe@foreach-labs.si',
          password: '7p4cA35AxWEVnb./7S',
        })
        .catch(error => {
          throw error;
        });
      if (response) {
        //set authorization token
        useJwt.setToken(response.data.access_token);
        return true;
      }
      return false;
    };

    const fetchData = async () => {
      const iframe = await axios.get(
        'iframe/hash/' + context.root.$route.params.hash,
      );
      var geo_locations = [];

      const res = await axios.get('iframe-data/device/' + iframe.data.id);
      res.data.forEach(element => {
        if (element.device.latitude != null && element.device.longitude != null)
          geo_locations.push(element.device);
      });
      // gets duplicate values of devices
      // sorry, please find a better way
      const filteredLocations = geo_locations.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      locations.value = filteredLocations;
    };

    return { locations };
  },
};
</script>
<style>

</style>
